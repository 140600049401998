.crossfade-hero {
  position: relative;
  width: 100%;
  // padding-bottom: 50%
  padding-bottom: 56%;
  margin: 0 auto;
  // margin-bottom: 70px
  margin-bottom: 2rem; }

.crossfade-imgWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  /* Start Animation */
  opacity: 0;
  animation-name: changeImg;
  animation-duration: 29.4s;
  animation-iteration-count: infinite; }

/* Object-Fit*/
.crossfade-imgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // below three lines are for skeleton loading
  z-index: 2;
  position: relative;
  transition: 1s; }

.crossfade-imgWrap2 {
  animation-delay: 4.2s; }

.crossfade-imgWrap3 {
  animation-delay: 8.4s; }

.crossfade-imgWrap4 {
  animation-delay: 12.6s; }

.crossfade-imgWrap5 {
  animation-delay: 16.8s; }

.crossfade-imgWrap6 {
  animation-delay: 21s; }

.crossfade-imgWrap7 {
  animation-delay: 25.2s; }

@keyframes changeImg {
  0% {
    opacity: 0; }

  2.4% {
    opacity: 1; }

  14.3142857% {
    opacity: 1; }

  16.7142857% {
    opacity: 0; }

  100% {
    opacity: 0; } }

/* End Animation */
